<template>
  <section class="views">
    <headers :isBack="true" title="報名成功"></headers>
    <div class="views_content">
      <img
        :src="require('@/assets/image/hodgepodge/ko.png')"
        alt=""
        class="kong"
      />

      <div class="h1_title">報名成功</div>

      <div class="h2_title" v-if="!isShool">
        你已報名成功，請留意電郵並詳閱比賽相關指引，謝謝！
      </div>
      <div class="h2_title" v-else>
        你已報名成功，請詳閱比賽相關指引，謝謝！
      </div>

      <div class="anniu" @click="but">
        <span>查看操作指引</span>
      </div>
    </div>
    <van-dialog
      v-model="showGuide"
      :show-close-button="false"
      :close-on-click-overlay="true"
      width="90%"
      position="center"
      confirm-button-text="關 閉"
    >
      <div class="image-container">
        <van-image
          :src="require('@/assets/image/H5_guide.jpg')"
          fit="contain"
        />
      </div>
    </van-dialog>
  </section>
</template>
  
  <script>
import headers from '@/components/header/custom'
import publics from '@/mixin/public_mixin'
export default {
  name: 'views',
  data() {
    return {
      showGuide: false,
      isShool: false,
    }
  },
  computed: {},
  mixins: [publics],
  components: {
    headers
  },
  watch: {},
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    this.isShool = urlParams.get('isShool')
  },
  mounted() {},
  methods: {
    but() {
      // this.$router.replace('/successful')
      // window.open(this.whatUrl,"_blank");
      // alert('还在开发中')
      this.showGuide = true
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .views_content {
    width: 100%;
    height: calc(100vh - 0.8rem);
    overflow-x: hidden;
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    align-content: center;

    .kong {
      width: 1.36rem;
      height: 1.36rem;
    }

    .h1_title {
      width: 100%;
      text-align: center;
      font-size: 0.32rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 0.24rem;
    }

    .h2_title {
      width: 100%;
      font-size: 0.28rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 0.24rem;
    }

    .anniu {
      margin: auto;
      margin-top: 0.48rem;
      width: 5.1rem;
      height: 0.96rem;
      text-align: center;
      font-size: 0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('~@/assets/image/hodgepodge/anniu.png') no-repeat;
      background-size: 100% 100%;

      img {
        width: 100%;
      }
    }
  }
}
.image-container {
  max-height: 80vh; /* 设置弹框内容的最大高度 */
  overflow-y: auto; /* 添加垂直滚动条 */
}

.van-dialog {
  top: 50%;
}
</style>
  