<template>
  <section class="views">
    <!-- header -->
    <headers select="-1"></headers>

    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner.jpg')"
      />
      <div class="posters_ewm" v-if="false">
        <div class="ewm">
          <div class="ewm2">
            <div class="ewm3">
              <img :src="require('@/assets/image/csewm.png')" alt="" />
            </div>
            <div class="ewm3_value">掃碼報名比賽</div>
          </div>
        </div>
      </div>
    </div>

    <!-- from -->
    <div class="content">
      <div class="contentFrom_titleBox">
        <div class="contentFrom_title">報名成功</div>
      </div>
      <div class="contentFrom_uls">
        <img
          :src="require('@/assets/image/hodgepodge/ko.png')"
          alt=""
          class="kong"
        />

        <div class="h1_title">報名成功</div>

        <div class="h2_title" v-if="!isShool">
          你已報名成功，請留意電郵並詳閱比賽相關指引，謝謝！
        </div>
        <div class="h2_title" v-else>
          你已報名成功，請詳閱比賽相關指引，謝謝！
        </div>

        <div class="anniu" @click="but">
          <span>查看操作指引</span>
        </div>

        <!-- <div class="erwm1">
          <img :src="require('@/assets/image/hodgepodge/ewm.png')" alt="" />
        </div>  -->
      </div>
    </div>

    <van-dialog
      v-model="showGuide"
      :show-close-button="false"
      :close-on-click-overlay="true"
      width="80%"
      z-index="99999"
      confirm-button-text="關 閉"
    >
      <div class="image-container">
        <van-image
          width="100%"
          :src="require('@/assets/image/PC_guide.jpg')"
          fit="contain"
        />
      </div>
    </van-dialog>
  </section>
</template>
 
<script>
import headers from '@/components/header/pc_header'
import publics from '@/mixin/public_mixin'
export default {
  name: 'views',
  data() {
    return {
      showGuide: false,
      isShool: false,
    }
  },
  computed: {},
  mixins: [publics],
  components: {
    headers
  },
  watch: {},
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    this.isShool = urlParams.get('isShool')
  },
  mounted() {},
  methods: {
    but() {
      // this.$router.replace('/successful')
      // window.open(this.whatUrl, '_blank')
      // alert('还在开发中')
      this.showGuide = true
      this.$nextTick(() => {
        // 动态浏览器视口高度
        const viewportHeight = window.innerHeight
        const browserInterfaceHeight = window.outerHeight - window.innerHeight
        const browserWindowHeight = viewportHeight - browserInterfaceHeight
        const imageContainer = document.querySelector('.image-container')
        imageContainer.style.height = `${browserWindowHeight}px`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;

  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      width: 1024px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: right;

      .ewm {
        width: 161px;
        height: 189px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 8px;
        box-sizing: border-box;

        .ewm2 {
          width: 100%;
          height: 100%;
          padding: 6px;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;

          .ewm3 {
            width: 132px;
            height: 132px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    max-width: 944px;
    margin: auto;
    margin-top: 83px;
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    .contentFrom_titleBox {
      width: 100%;
      height: 52px;
      position: relative;

      .contentFrom_title {
        width: 265px;
        height: 52px;
        margin: auto;
        border-radius: 9px;
        background: url('~@/assets/image/icon/titlebg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 23px;
        -webkit-text-stroke: 1px #ff782f;
        text-stroke: 1px #ff782f;
        line-height: 52px;
        position: absolute;
        top: -47%;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .contentFrom_uls {
      padding: 0px 158px 50px 158px;
      width: 100%;
      height: 100%;
      min-height: 400px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      box-sizing: border-box;
      align-content: center;
      .kong {
        width: 68px;
        height: 68px;
      }

      .h1_title {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 18px;
      }

      .h2_title {
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        margin-top: 18px;
      }

      .anniu {
        width: 275px;
        height: 48px;
        text-align: center;
        margin: auto;
        margin-top: 24px;
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('~@/assets/image/hodgepodge/anniu.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .erwm1 {
        width: 100%;

        img {
          width: 240px;
          height: 240px;
          margin-top: 16px;
        }
      }
    }
  }
}
.image-container {
  padding: 16px;
  min-height: 600px;
  overflow-y: auto;
}
.van-dialog {
  top: 50%;
}
</style>